<template>
  <div class="page-index">
    <Header background-image="images/hero.jpg">
      <h2 class="header__title">Finden Sie den Spezialisten in Ihrer Region</h2>

      <h3 class="header__subtitle">
        Vergleichen Sie Angebote, bevor Sie sich entscheiden, und sparen Sie bis
        zu 40 %!
      </h3>

      <TCard class="header__card">
        <div class="card__form">
          <PostalFormStep :progress-value="0" />
        </div>
      </TCard>

      <span class="header__subtext">
        Vergleichen Sie 6 Anbieter von Solarmodulen in Ihrer Region
      </span>
    </Header>

    <HomeBlock id="usps">
      <Usps :usps="usps" />
    </HomeBlock>
  </div>
</template>

<script>
import AbstractHomePage from 'chimera/all/components/page/AbstractHomePage'
import HomeBlock from 'chimera/all/components/HomeBlock'
import Usps from 'chimera/all/themes/blueflow/components/Usps'
import Header from 'chimera/all/themes/blueflow/components/layout/Header'
import PostalFormStep from '~/components/form/steps/postal/PostalFormStep'

export default {
  components: {
    Header,
    HomeBlock,
    PostalFormStep,
    Usps,
  },

  extends: AbstractHomePage,

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Solaranlagevergleicher.de',
      headTitleTemplate: '%s',
      headDescription:
        'Geben Sie Ihre Postleitzahl ein und vergleichen Sie kostenlos 6 Angebote von Top-Unternehmen in Ihrer Region. ✓ Der beste Preis für Ihren Solarmodullieferanten ✓ Sparen Sie bis zu 40 % ✓ 100 % kostenlos!',
      path: '/',
      usps: [
        {
          title: 'Beantworten Sie ein paar Fragen',
          text: 'Sie sagen uns, was Sie suchen, damit wir Ihnen den richtigen Spezialisten aus Ihrer Region vermitteln können.',
          logo: require('chimera/all/themes/blueflow/images/usps/pencil.svg'),
        },
        {
          title: 'Kostenlose Angebote einholen',
          text: 'Sie erhalten bis zu sechs Angebote von Spezialisten, die Ihren Anforderungen entsprechen. Dadurch sparen Sie bis zu 40 %!',
          logo: require('chimera/all/themes/blueflow/images/usps/offer.svg'),
        },
        {
          title: 'Wählen Sie den richtigen Spezialisten',
          text: 'Wir haben unsere Spezialisten bereits überprüft, Sie müssen nur noch die Angebote vergleichen und auswählen.',
          logo: require('chimera/all/themes/blueflow/images/usps/user.svg'),
        },
      ],
    }
  },
}
</script>
